import React from 'react'
import { graphql } from 'gatsby'
import { Container, Box, Flex } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import LogosStrip from '@solid-ui-components/LogosStrip'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import ModalPortfolio from '@solid-ui-blocks/Modal/Block03'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Features from '@solid-ui-blocks/Features/Block05'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import FeatureOneRemote from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import Condetions from '@solid-ui-blocks/Features/Block01'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block06'
import FeatureThree from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import FeatureThreeMore from '@solid-ui-blocks/Features/Block06'
import Blog from '@solid-ui-blocks/Blog/Block01'
import Services from '@solid-ui-blocks/Services/Block01'
import Faq from '@solid-ui-blocks/Faq/Block01'
import Footer from '@solid-ui-blocks/Footer/Block02'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
import useSiteMetadata from '@blocks-helpers/useSiteMetadata'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'
// aaaaaaa
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import QuickSignupForm from '@solid-ui-components/QuickSignupForm'

import FlexImage from '@solid-ui-components/FlexImage'
import FlexContent from '@solid-ui-components/FlexContent'
import FlexOverlapFade from '@solid-ui-components/FlexOverlapFade'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import ContentVideo from '@solid-ui-components/ContentVideo'
import ContentMap from '@solid-ui-components/ContentMap'

// import homepageHero from './assets/images/hero.jpeg'

const buildCTA = type => {
  let action
  switch (type) {
    case 'Appointment':
      action = [
        {
          text: 'Request Appointment',
          type: 'PAGE',
          target: '_blank',
          link: 'https://practice.kareo.com/book-an-appointment',
          variant: 'secondary'
        }
      ]
      break
    case 'Form':
      action = [
        {
          text: 'Contact us',
          type: 'MODAL',
          link: 'contact',
          variant: 'dark',
          bg: 'beta'
        }
      ]
      break
    case 'Call':
      action = [
        {
          text: 'Call us: (224) 735-3522',
          type: 'PAGE',
          target: '_blank',
          link: 'tel://+12247353522',
          variant: 'links.nav-dark'
        }
      ]
      break
    default:
      action = [{}]
      break
  }
  return action
}

const LandingPage = props => {
  const { node, location, slug } = props.pageContext
  let conditionsItems = node.conditions.map(item => {
    return {
      text: [
        {
          text: item.trim(),
          color: 'omegaDarker',
          variant: 'h6'
        },
        {
          text: '',
          color: 'omegaDarker'
        }
      ],
      icon: {
        src: '/icons/bx-check-circle.svg',
        color: 'beta'
      }
    }
  })

  const pageStyles = {
    heroContainer: {
      position: `relative`,
      // bg: `#F6F7FA`,
      // color: `#F6F7FA`,
      py: [6, 7],
      '::before, ::after': {
        position: `absolute`,
        content: `" "`,
        width: `full`,
        // height: `85%`,
        // top: [`25%`, null, null, `50%`],
        // right: `50%`,
        height: `100%`,

        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        // transform: `translate(50%, -50%)`,
        zIndex: -1
        // borderRadius: `0`
      },
      // https://drive.google.com/uc?id=${node.heroImage}
      // https://drive.google.com/uc?id=1kRvyxqPhENSTKXelQO1pPiWg2N2nZDlG
      '::after': {
        background: `linear-gradient(to right, rgba(0,0,0,0.7), transparent)`
        // background: `linear-gradient(
        //   180deg,
        //   #F6F7FA 0%,
        //   rgba(255,255,255,0.8) 40%,
        //   rgba(255,255,255,0.8) 70%,
        //   #e2e8f0 100%
        // )`
      },
      // var url = https://drive.google.com/open?id=yourID
      // var id = url.field2.split("?")[1].split("id=");

      '::before': {
        background: `url("${node.heroImage.childImageSharp.gatsbyImageData.images.fallback.src}") no-repeat center center`,

        // background: `url(https://drive.google.com/uc?id=${node.heroImage}) no-repeat center center`,
        backgroundSize: `cover`
      }
      // '::before': {
      //   zIndex: -3,
      //   bg: `white`,
      //   background: t => `linear-gradient(
      //     145deg,
      //     ${t.colors.alpha} 0%,
      //     ${t.colors.alphaDarker} 100%
      //   )`
      // },
      // '::after': {
      //   zIndex: -2,
      //   background: `url(${homepageHero}) repeat right top`,
      //   opacity: 0.3
      // }
    },
    conditionsContainer: {
      bg: `omegaLighter`,
      // borderRadius: `0`,
      // py: [2, 2]
      py: 5
      // px: [4, 0]
    },
    insuranceContainer: {
      bg: `omegaLight`,
      py: 4,
      px: [4, 0],
      borderTop: '2px solid #c4cfdf'
    },
    buttonsWrapper: {
      textAlign: `center`
    }
  }

  const form = {
    fields: [
      {
        identifier: 'name',
        type: 'TEXT',
        placeholder: {
          text: 'Full Name'
        },
        icon: {
          src: '/icons/bx-user.svg',
          color: 'alpha'
        }
      },
      {
        identifier: 'email',
        type: 'EMAIL',
        placeholder: {
          text: 'Email Address'
        },
        icon: {
          src: '/icons/bx-envelope.svg',
          color: 'alpha'
        }
      },
      {
        identifier: 'password',
        type: 'PASSWORD',
        placeholder: {
          text: 'Password'
        },
        icon: {
          src: '/icons/bx-lock-alt.svg',
          color: 'alpha'
        }
      },
      {
        identifier: 'confirm-password',
        type: 'PASSWORD',
        placeholder: {
          text: 'Confirm Password'
        },
        icon: {
          src: '/icons/bx-lock-alt.svg',
          color: 'alpha'
        }
      },
      {
        identifier: 'agree',
        type: 'CHECKBOX',
        placeholder: {
          text: 'I agree to all statements in the Terms of Use.'
        },
        icon: {
          src: '/icons/bx-lock-alt.svg',
          color: 'alpha'
        }
      }
    ],
    buttons: [
      {
        text: 'Create Account',
        type: 'PAGE',
        link: '',
        variant: 'secondary',
        width: '200px'
      }
    ]
  }

  const reverse = false

  const {
    allBlockContent,
    googlePlacesPlace: {
      rating,
      user_ratings_total,
      childrenGooglePlacesReview
    }
  } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const siteMetadata = useSiteMetadata()

  // console.log('node: ', props.pageContext)
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'http://schema.org/LocalBusiness',
    name: node.seoTitle,
    // description: siteMetadata.description,
    url: `${siteMetadata.siteUrl}/${slug}`,
    logo: siteMetadata.logo,
    sameAs: siteMetadata.social,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: rating,
      reviewCount: user_ratings_total
    },
    address: {
      '@type': 'PostalAddress',
      streetAddress: '1925 East Rand Road',
      addressLocality: 'Cook County',
      addressRegion: 'Illinois',
      postalCode: '60004'
    },
    description:
      'Rand Medical Center is located in Cook County of Illinois state. On the street of East Rand Road and street number is 1925. For more info please call (224) 735-3522',
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 42.0985394,
      longitude: -87.9600838
    },
    telephone: '+1 224-735-3522',
    openingHours: [
      'Monday: 9:00 AM - 5:00 PM',
      'Tuesday: 9:00 AM - 5:00 PM',
      'Wednesday: 9:00 AM - 5:00 PM',
      'Thursday: 9:00 AM - 5:00 PM',
      'Friday: 9:00 AM - 5:00 PM',
      'Saturday: Closed',
      'Sunday: Closed'
    ],
    image: {
      '@type': 'ImageObject',
      url: `${siteMetadata.siteUrl}${node.heroImage.childImageSharp.gatsbyImageData.images.fallback.src}`,
      contentUrl: `${siteMetadata.siteUrl}${node.heroImage.childImageSharp.gatsbyImageData.images.fallback.src}`,
      thumbnailUrl: `${siteMetadata.siteUrl}${node.heroImage.childImageSharp.gatsbyImageData.images.fallback.src}`
    },
    mainEntityOfPage: {
      '@type': 'MedicalWebPage',
      '@id': `${siteMetadata.siteUrl}/${slug}`,
      headline: `${node.serviceName}`
    }
    // audience	: {
    //   "@type": "Patient",
    //   "name": "https://schema.org/Patient"
    // },
    // specialty: `${node.serviceName}`
  }

  return (
    <Layout theme={theme} {...props} landingPage={true}>
      <Seo
        title={`${node.seoTitle} near ${location.city}`}
        description={`${node.seoBlurb}`}
        uri={props.uri}
        thumbnail={node.heroImage.childImageSharp.gatsbyImageData}
        indexed={node.searchEngine ? true : false}
        keywords={node.conditions ? node.conditions : []}
        schemaMarkup={schema}
      />

      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      {/* Blocks */}
      <Header content={content['header-landing']} />
      <Container variant='full' sx={pageStyles.heroContainer}>
        <Container
          sx={{
            position: `relative`,
            textAlign: ['center', 'left'],
            flexGrow: 1,
            pt: [6, 0]
          }}
        >
          <Flex
            sx={{
              alignItems: [null, `center`],
              flexDirection: [
                reverse ? `column-reverse` : `column`,
                reverse ? `row-reverse` : `row`
              ],
              mx: [null, null, null, -4]
            }}
          >
            <FlexContent reverse={reverse}>
              <Box sx={{ textAlign: ['center', 'left'] }}>
                <ContentText
                  content={[
                    // {
                    //   text: node.title,
                    //   color: '#cccccc',
                    //   variant: 'h4'
                    // },
                    {
                      text: `${node.title} in ${location.city}`,
                      variant: 'h1',
                      color: 'white',
                      ml: 0
                    },
                    {
                      text: node.blurb,
                      variant: 'h3',
                      color: 'white'
                    }
                  ]}
                />
              </Box>
              <>
                <Divider space={3} />
                <ContentButtons content={buildCTA(node.heroAction)} />
              </>
            </FlexContent>
          </Flex>
          <FlexOverlapFade direction={reverse ? 'ltr' : 'rtl'} />
        </Container>
      </Container>
      <Container variant='full' sx={pageStyles.insuranceContainer}>
        <LogosStrip content={content['insurance']} />
      </Container>
      <Divider space='4' />
      <Divider space='4' />
      <Container sx={styles.featureOneContainer}>
        <FeatureOneRemote
          content={{
            text: [
              {
                text: node.serviceName,
                color: 'beta',
                variant: 'h4'
              },
              {
                text: node.serviceTitle,
                variant: 'h2'
              },
              {
                text: node.serviceDescription,
                variant: 'medium'
              }
            ],
            images: [
              {
                src: node.serviceImage?.childImageSharp,
                alt: '',
                radius: 'lg',
                shadow: 'xl',
                effects: ['fadeInDown']
              }
            ],
            buttons: buildCTA(node.serviceAction),
            collection: []
          }}
        />
      </Container>

      {/* <Divider space='4' />
      <Divider space='4' />
      <Services content={content['services']} id='services' /> */}
      {node.conditionsTitle && (
        <>
          <Divider space='4' />
          <Divider space='4' />
          <Container variant='full' sx={pageStyles.conditionsContainer}>
            <Condetions
              wrapperStyles={pageStyles.buttonsWrapper}
              content={{
                text: [
                  {
                    text: node.conditionsTitle,
                    color: 'beta',
                    variant: 'h4'
                  },
                  {
                    text: node.conditionsDescription,
                    color: 'alpha',
                    variant: 'h4'
                  }
                ],
                collection: node.conditions ? conditionsItems : [],
                buttons: buildCTA(node.conditionsAction)
              }}
            />
          </Container>
        </>
      )}
      {/* <Divider space='4' />
      <Divider space='4' />
      <Container sx={styles.featureOneContainer}>
        <FeatureOne content={content['feature-one']} reverse />
      </Container> */}
      {/* <Divider space='4' />
      <Divider space='4' />
      <Container sx={styles.featureOneContainer}>
        <FeatureOne content={content['feature-two']} />
      </Container> */}
      {/* <Divider space='4' />
      <Divider space='4' />
      <Container variant='full'>
        <Contact content={content['pricing-and-insurance']} />
      </Container> */}
      {/* <Divider space='4' />
      <Divider space='4' />
      <Container sx={styles.featureOneContainer}>
        <FeatureOne content={content['feature-three']} reverse />
      </Container>
      <Divider space='4' />
      <Divider space='4' />
      <Container sx={styles.featureOneContainer}>
        <FeatureOne content={content['feature-four']} />
      </Container>
      <Divider space='4' />
      <Divider space='4' />
      <Container sx={styles.featureOneContainer}>
        <FeatureOne content={content['feature-five']} reverse />
      </Container> */}
      <Divider space='4' />
      <Divider space='4' />
      <Divider space='4' />
      <Testimonials content={content['testimonials']} />

      {node.faq1 && (
        <>
          <Divider space='4' />
          <Divider space='4' />
          <Container variant='full'>
            <Faq
              content={{
                text: [
                  {
                    text: 'We Are Here To Help You',
                    color: 'beta',
                    variant: 'h4'
                  },
                  {
                    text: 'Frequently Asked Questions',
                    variant: 'h2'
                  }
                ],
                collection: [
                  {
                    text: [
                      {
                        text: `${node.faq1}`,
                        variant: 'h5'
                      },
                      {
                        text: `${node.faq1Answer}`
                      }
                    ],
                    icon: {
                      src: '/icons/bx-directions.svg',
                      bg: 'white',
                      color: 'alpha'
                    }
                  },
                  {
                    text: [
                      {
                        text: `${node.faq2}`,
                        variant: 'h5'
                      },
                      {
                        text: `${node.faq2Answer}`
                      }
                    ],
                    icon: {
                      src: '/icons/bx-directions.svg',
                      bg: 'white',
                      color: 'alpha'
                    }
                  },
                  {
                    text: [
                      {
                        text: `${node.faq3}`,
                        variant: 'h5'
                      },
                      {
                        text: `${node.faq3Answer}`
                      }
                    ],
                    icon: {
                      src: '/icons/bx-directions.svg',
                      bg: 'white',
                      color: 'alpha'
                    }
                  }
                ]
              }}
            />
          </Container>
        </>
      )}

      <Divider space='4' />
      <Divider space='4' />
      <div style={{ width: `100%`, height: `600px` }}>
        <ContentMap
          content={{
            lat: location.lat ? location.lat : 42.0985394,
            lng: location.lng ? location.lng : -87.9600665,
            zoom: 14
          }}
        />
      </div>
      <Footer content={content['footer2']} />
    </Layout>
  )
}

export const query = graphql`
  query landingPageOneRandBlockContent {
    allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
    googlePlacesPlace {
      name
      rating
      childrenGooglePlacesReview {
        author_name
        text
        rating
        profile_photo_url
      }
      user_ratings_total
    }
  }
`
export default LandingPage
